import { Typography } from "antd";

const { Title, Paragraph, Text } = Typography;

const WorkflowOI = () => {
  return (
    <Typography style={{ position: 'absolute', bottom: 20, right: 20, fontSize: 18, textAlign: 'right', pointerEvents: 'none' }}>
      <Title level={4}>操作指南</Title>
      <Paragraph>
        <Text keyboard>Ctrl</Text>+<Text code>鼠标左键</Text>
        <Text> - 拖动画板</Text>
      </Paragraph>
      <Paragraph>
        <Text keyboard>Ctrl</Text>+<Text code>鼠标滚轮</Text>
        <Text> - 缩放画板</Text>
      </Paragraph>
      <Paragraph>
        <Text code>鼠标双击画板</Text>
        <Text> - 创建任务</Text>
      </Paragraph>
      <Paragraph>
        <Text code>鼠标双击任务</Text>
        <Text> - 修改任务</Text>
      </Paragraph>
      <Paragraph>
        <Text keyboard>回车键</Text>
        <Text> - 确定创建</Text>
      </Paragraph>
      <Paragraph>
        <Text keyboard>ESC</Text>
        <Text> - 关闭创建</Text>
      </Paragraph>
      <Paragraph>
        <Text code>鼠标左键单击</Text>
        <Text> - 选中任务</Text>
      </Paragraph>
      <Paragraph>
        <Text code>鼠标左键按住</Text>
        <Text> - 拖动任务</Text>
      </Paragraph>
      <Paragraph>
        <Text code>选中任务</Text>+<Text code>退格键 OR DELETE</Text>
        <Text> - 删除任务</Text>
      </Paragraph>
    </Typography>
  );
};

export default WorkflowOI;
