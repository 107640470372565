import { getScreenCoord } from "@/utils/svg.util";
import curImg from '@/assets/cut.svg';
import styles from './WorkflowLine.module.less';

type Props = {
  options: Workflow.LineOptions;
  offset: Workflow.Offset;
  scale: number;
  onCut?: (id: number, type: Workflow.LineOptions['cutType']) => void;
};

const WorkflowLine = (props: Props) => {
  const { options, offset, scale, onCut } = props;

  const [x1, y1] = getScreenCoord([options.x1, options.y1], scale, [offset.left, offset.top]);
  const [x2, y2] = getScreenCoord([options.x2, options.y2], scale, [offset.left, offset.top]);
  const strokeWidth = 2 * scale;

  const cutSize = { width: 16 * scale, height: 16 * scale };

  const handleCutClick: React.MouseEventHandler<SVGImageElement> = (e) => {
    e.stopPropagation();
    if (onCut) onCut(options.taskId, options.cutType);
  };

  return (
    <g className={styles.g}>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke="gray"
        strokeWidth={strokeWidth}
      />
      <image
        fill="red"
        stroke="red"
        xlinkHref={curImg}
        x={(x1 + x2) / 2 - cutSize.width / 2}
        y={(y1 + y2) / 2 - cutSize.height / 2}
        width={cutSize.width}
        height={cutSize.height}
        xlinkTitle="断开"
        onClick={handleCutClick}
      />
    </g>
  );
};

export default WorkflowLine;
