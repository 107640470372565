import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Input, message, Modal, Typography } from 'antd';
import { getScreenCoord, getSvgCoord, getSvgFixedPointOffset } from '@/utils/svg.util';
import { getTaskCanvasOptions } from '@/utils/workflow.util';
import { gNormalId } from '@/utils/id.util';
import WorkflowTask from './WorkflowTask';
import WorkflowBg from './WorkflowBg';
import WorkflowOI from './WorkflowOI';
import WorkflowBeacon from './WorkflowBeacon';
import styles from './Workflow.module.less';
import WorkflowLine from './WorkflowLine';

type InputOptions = {
  visible: boolean;
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  defaultValue?: string;
  taskId?: number;
};

const Workflow = () => {
  // 容器
  const container = useRef<HTMLDivElement>(null);
  // 画布
  const canvas = useRef<SVGSVGElement>(null);
  // svg尺寸
  const [svgSize, setSvgSize] = useState({ width: 0, height: 0 });
  // 偏移量
  const [offset, setOffset] = useState({ left: 0, top: 0 });
  // 缩放倍数
  const [scale, setScale] = useState(1);
  // 背景线选项
  const [bgLineOptions] = useState({
    gap: 20, // 间隔
    stroke: 'rgba(0, 0, 0, .1)',
    strokeWidth: 1,
  });
  // 输入框配置
  const [inputOptions, setInputOptions] = useState<InputOptions>({ left: 0, top: 0, visible: false });
  // 任务列表
  const [tasks, setTasks] = useState<Workflow.Task[]>([]);
  // 选中的任务
  const [selectedTaskKeys, setSelectedTaskKeys] = useState<(string | number)[]>([]);
  // 连接线
  const initConnectLine = { x1: 0, y1: 0, x2: 0, y2: 0, strokeWidth: 0 };
  const [connectLine, setConnectLine] = useState({ ...initConnectLine });

  const relationLines = useMemo(() => {
    return tasks.reduce<Workflow.LineOptions[]>((lines, task, _index, tasks) => {
      if (task.parentId) {
        const parentTask = tasks.find(item => item.id === task.parentId);
        if (parentTask) {
          const endpoint1 = parentTask.canvasOptions.endpoints.find(item => item.position === 'bottom');
          const endpoint2 = task.canvasOptions.endpoints.find(item => item.position === 'top');
          if (endpoint1 && endpoint2) {
            lines.push({
              key: `${task.id}-top`,
              x1: endpoint1.x,
              y1: endpoint1.y,
              x2: endpoint2.x,
              y2: endpoint2.y,
              taskId: task.id,
              cutType: 'parent',
            });
          }
        }
      }
      if (task.lastId) {
        const lastTask = tasks.find(item => item.id === task.lastId);
        if (lastTask) {
          const endpoint1 = lastTask.canvasOptions.endpoints.find(item => item.position === 'right');
          const endpoint2 = task.canvasOptions.endpoints.find(item => item.position === 'left');
          if (endpoint1 && endpoint2) {
            lines.push({
              key: `${task.id}-left`,
              x1: endpoint1.x,
              y1: endpoint1.y,
              x2: endpoint2.x,
              y2: endpoint2.y,
              taskId: task.id,
              cutType: 'last',
            });
          }
        }
      }
      return lines;
    }, []);
  }, [tasks]);

  // 窗口大小变化
  useLayoutEffect(() => {
    if (container.current) {
      const elem = container.current;
      const resize = () => {
        setSvgSize({ width: elem.offsetWidth, height: elem.offsetHeight });
      };
      const resizeObserver = new ResizeObserver(resize);
      resizeObserver.observe(elem);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  // 画布缩放
  useLayoutEffect(() => {
    if (canvas.current) {
      const elem = canvas.current;
      const handleWheel = (e: WheelEvent) => {
        if (e.ctrlKey) {
          e.preventDefault();
          e.stopPropagation();
          const [currentX, currentY] = getSvgCoord([e.offsetX, e.offsetY], scale, [offset.left, offset.top]);
          let nextScale = e.deltaY > 0 ? scale - 0.2 : scale + 0.2;
          if (nextScale > 4) {
            nextScale = 4;
            console.log('最大缩放倍数为4倍');
          }
          if (nextScale < 0.4) {
            nextScale = 0.4;
            console.log('最小缩放倍数为0.4倍');
          }
          const [offsetLeft, offsetTop] = getSvgFixedPointOffset([currentX, currentY], [offset.left, offset.top], scale, nextScale);
          setScale(nextScale);
          setOffset({ left: offsetLeft, top: offsetTop });
        }
      };
      elem.addEventListener('wheel', handleWheel, { passive: false });
      return () => {
        elem.removeEventListener('wheel', handleWheel);
      };
    }
  }, [scale, offset]);

  // 画布拖拽
  const handleSvgMouseDown: React.MouseEventHandler<SVGSVGElement> = (e) => {
    if (e.ctrlKey) {
      const elem = e.currentTarget;
      const initX = e.clientX, initY = e.clientY;
      const { left: offsetLeft, top: offsetTop } = offset;
      const handleMouseMove = (e: MouseEvent) => {
        const x = e.clientX, y = e.clientY;
        setOffset({ left: offsetLeft - (x - initX) / scale, top: offsetTop - (y - initY) / scale });
      };
      elem.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', () => {
        elem.removeEventListener('mousemove', handleMouseMove);
      }, { once: true });
    }
  };

  // 打开输入框
  const openInput = (options?: { [key in keyof InputOptions]?: InputOptions[key] }) => {
    setInputOptions({
      ...options,
      visible: true,
    });
  };

  // 关闭输入框
  const closeInput = () => {
    setInputOptions({ visible: false });
  };

  // 创建任务
  const createTask = (options: Workflow.TaskCreateOptions) => {
    const { title, origin } = options;
    const id = gNormalId();
    const canvasOptions = getTaskCanvasOptions(title, origin);
    const task: Workflow.Task = {
      id,
      title,
      lastPath: `,${id},`,
      parentPath: `,${id},`,
      canvasOptions,
    };
    setTasks([...tasks, task]);
    setSelectedTaskKeys([id]);
  };

  // 更新任务
  const updateTask = (id: number, options: Workflow.TaskUpdateOptions) => {
    const task = tasks.find(item => item.id === id);
    if (!task) return message.error(`任务不存在(${id})`);
    if (Object.hasOwn(options, 'title')) {
      if (!options.title) return message.error('任务名称不能为空');
      task.title = options.title;
    }
    if (Object.hasOwn(options, 'origin')) {
      if (!options.origin) return message.error('任务坐标原点不能为空');
      task.canvasOptions = getTaskCanvasOptions(task.title, options.origin);
    }
    setTasks([...tasks]);
  };

  // 双击画板
  const handleSvgDoubleClick: React.MouseEventHandler<SVGSVGElement> = (e) => {
    const left = e.nativeEvent.offsetX, top = e.nativeEvent.offsetY;
    openInput({ left, top });
  };

  // 单击画板
  const handleSvgClick: React.MouseEventHandler<SVGSVGElement> = (_e) => {
    setSelectedTaskKeys([]);
  };

  // 画板按键事件
  useEffect(() => {
    let disabled = false;
    const handleSvgKeyDown = (e: KeyboardEvent) => {
      if (disabled) return;
      const code = e.code;
      switch(code) {
        case 'Backspace':
        case 'Delete':  {
          if (selectedTaskKeys.length === 0) return;
          const selectedTasks = tasks.filter(item => selectedTaskKeys.includes(item.id));
          if (selectedTasks.length === 0) return;
          const keys = selectedTasks.map(item => item.id);
          disabled = true;
          Modal.confirm({
            title: '删除任务',
            content: (
              <div>
                确定要删除
                {selectedTasks.map(item => (
                  <Typography.Text code key={item.id}>{item.title}</Typography.Text>
                ))}
                这 <Typography.Text mark>{selectedTasks.length}</Typography.Text> 个任务么？
              </div>
            ),
            onOk: () => {
              setTasks(tasks.filter(item => !keys.includes(item.id)));
              setSelectedTaskKeys([]);
              disabled = false;
            },
            onCancel: () => {
              disabled = false;
            },
          });
          return;
        };
      }
    };
    window.addEventListener('keydown', handleSvgKeyDown);
    return () => {
      window.removeEventListener('keydown', handleSvgKeyDown);
    };
  }, [selectedTaskKeys, tasks]);

  // 输入框按键事件
  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    const code = e.code;
    switch(code) {
      // ESC 取消输入
      case 'Escape': {
        closeInput();
        return;
      }
      // 回车 确认输入
      case 'Enter':
      case 'NumpadEnter': {
        const title = (e.target as HTMLInputElement).value.trim();
        if (!title) {
          message.warn('标题不能为空');
          return;
        }
        if (inputOptions.taskId) {
          updateTask(inputOptions.taskId, { title });
        } else {
          const origin = getSvgCoord([inputOptions.left || 0, inputOptions.top || 0], scale, [offset.left, offset.top]);
          createTask({ title, origin });
        }
        closeInput();
        return;
      }
    }
  };

  // 任务选中状态改变
  const handleTaskSelectedChange = (selected: boolean, key: number, multi: boolean) => {
    if (selected) {
      setSelectedTaskKeys(multi ? [...selectedTaskKeys, key] : [key]);
    } else {
      setSelectedTaskKeys(multi ? selectedTaskKeys.filter(item => item !== key) : []);
    }
  };

  // 显示任务名称编辑器
  const showTaskTitleEditor = (task: Workflow.Task) => {
    const { origin, size } = task.canvasOptions;
    const [left, top] = getScreenCoord(origin, scale, [offset.left, offset.top]);
    const width = size.width * scale, height = size.height * scale;
    openInput({
      left,
      top,
      width,
      height,
      defaultValue: task.title,
      taskId: task.id,
    });
  };

  const handleTaskConnectStart = (coord: Workflow.Coordinate)  => {
    setConnectLine({
      x1: coord[0],
      y1: coord[1],
      x2: coord[0],
      y2: coord[1],
      strokeWidth: 2,
    });
  };

  const handleTaskConnecting = (coord: Workflow.Coordinate) => {
    setConnectLine((obj) => ({
      ...obj,
      x2: coord[0],
      y2: coord[1],
    }));
  };

  const handleTaskConnectEnd = (position: string, startId: number, endId: number) => {
    const startTask = tasks.find(item => item.id === startId);
    const endTask = tasks.find(item => item.id === endId);
    if (!startTask || !endTask) return;
    switch(position) {
      case 'top':
      case 'bottom': {
        const parentTask = position === 'bottom' ? startTask : endTask;
        const childTask = position === 'top' ? startTask : endTask;
        if (parentTask.parentPath.includes(`,${childTask.id},`)) return;
        if (childTask.lastPath.split(',').some(id => id && parentTask.parentPath.includes(`,${id},`))) return;
        childTask.parentId = parentTask.id;
        const oldParentPath = childTask.parentPath;
        const newParentPath = `${parentTask.parentPath}${childTask.id},`;
        tasks.forEach(task => {
          const reg = new RegExp(`^${oldParentPath}`)
          if (reg.test(task.parentPath)) {
            task.parentPath = task.parentPath.replace(reg, newParentPath);
          }
        });
        setTasks([...tasks]);
        break;
      }
      case 'left':
      case 'right': {
        const lastTask = position === 'right' ?  startTask : endTask;
        const nextTask = position === 'left' ? startTask : endTask;
        if (lastTask.lastPath.includes(`,${nextTask.id},`)) return;
        if (nextTask.parentPath.split(',').some(id => id && lastTask.lastPath.includes(`,${id},`))) return;
        nextTask.lastId = lastTask.id;
        const oldLastPath = nextTask.lastPath;
        const newLastPath = `${lastTask.lastPath}${nextTask.id},`;
        tasks.forEach(task => {
          const reg = new RegExp(`^${oldLastPath}`);
          if (reg.test(task.lastPath)) {
            task.lastPath = task.lastPath.replace(reg, newLastPath);
          }
        });
        setTasks([...tasks]);
        break;
      }
    }
  };

  const handleTaskConnectClear = () => {
    setConnectLine({ ...initConnectLine });
  };

  const handleTaskDisonnect = (id: number, type: Workflow.LineOptions['cutType']) => {
    const task = tasks.find(item => item.id === id);
    if (!task) return;
    if (type === 'last') {
      task.lastId = undefined;
      const oldPath = task.lastPath;
      const newPath = `,${task.id},`;
      tasks.forEach(task => {
        const reg = new RegExp(`^${oldPath}`);
        if (reg.test(task.lastPath)) {
          task.lastPath = task.lastPath.replace(reg, newPath);
        }
      });
    } else {
      task.parentId = undefined;
      const oldPath = task.parentPath;
      const newPath = `,${task.id},`;
      tasks.forEach(task => {
        const reg = new RegExp(`^${oldPath}`);
        if (reg.test(task.parentPath)) {
          task.parentPath = task.parentPath.replace(reg, newPath);
        }
      });
    }
    setTasks([...tasks]);
  };

  // 禁止svg事件，条件: 1. 当输入框出现时
  const disableSvgEvent = inputOptions.visible;

  return (
    <div className={styles.container} ref={container}>
      <svg
        ref={canvas}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        style={{ pointerEvents: disableSvgEvent ? 'none' : 'all' }}
        width={svgSize.width}
        height={svgSize.height}
        onMouseDown={handleSvgMouseDown}
        onDoubleClick={handleSvgDoubleClick}
        onClick={handleSvgClick}
      >
        <WorkflowBg
          width={svgSize.width}
          height={svgSize.height}
          lineOptions={bgLineOptions}
          scale={scale}
          offset={offset}
        />
        <g>
          {relationLines.map(options => (
            <WorkflowLine
              key={options.key}
              options={options}
              offset={offset}
              scale={scale}
              onCut={handleTaskDisonnect}
            />
          ))}
        </g>
        <g>
          <line
            x1={connectLine.x1}
            y1={connectLine.y1}
            x2={connectLine.x2}
            y2={connectLine.y2}
            stroke="gray"
            strokeWidth={connectLine.strokeWidth}
          />
        </g>
        <g>
          {tasks.map(item => (
            <WorkflowTask
              key={item.id}
              task={item}
              offset={offset}
              scale={scale}
              selected={selectedTaskKeys.includes(item.id)}
              onSelectedChange={handleTaskSelectedChange}
              showTitleEditor={showTaskTitleEditor}
              onChange={updateTask}
              onConnectStart={handleTaskConnectStart}
              onConnecting={handleTaskConnecting}
              onConnectEnd={handleTaskConnectEnd}
              onConnectClear={handleTaskConnectClear}
            />
          ))}
        </g>
      </svg>
      {inputOptions.visible && (
        <Input
          defaultValue={inputOptions.defaultValue}
          className={styles.input}
          autoFocus
          onBlur={e => e.target.focus()}
          onKeyDown={handleInputKeyDown}
          style={{
            left: inputOptions.left ? inputOptions.left - 12 : 0,
            top: inputOptions.top ? inputOptions.top - 16 : 0,
            width: inputOptions.width ? inputOptions.width + 24 : '',
          }}
        />
      )}
      <WorkflowBeacon
        offset={offset}
        scale={scale}
        onOffsetChange={offset => setOffset(offset)}
      />
      <WorkflowOI />
    </div>
  );
};

export default Workflow;