import { getSvgTextSize } from "./svg.util";

export const getTaskCanvasOptions = (title: string, origin: Workflow.Coordinate) => {
  const [x, y] = origin;
  const fontSize = 20;
  const padding = { x: 8, y: 8 };
  const arcR = { x: 8, y: 0 };
  const { width, height } = getSvgTextSize(title, { fontSize: `${fontSize}px` });
  const endPointR = 4;
  const endpoints: Workflow.Endpoint[] = [
    { position: 'top', x: x + width / 2, y: y - height / 2 - padding.y - arcR.y, r: endPointR },
    { position: 'right', x: x + width + padding.x + arcR.x, y, r: endPointR },
    { position: 'bottom', x: x + width / 2, y: y + height / 2 + padding.y + arcR.y, r: endPointR },
    { position: 'left', x: x - padding.x - arcR.x, y, r: endPointR },
  ];

  return {
    origin,
    fontSize,
    size: { width, height },
    padding,
    arcR,
    endpoints,
  };
};
