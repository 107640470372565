/* 获取文本大小 */
export const getSvgTextSize = (content: string, styles: { [key in keyof CSSStyleDeclaration]?: string; }) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.style.position = 'absolute';
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  text.textContent = content;
  for (const key in styles) {
    const value = styles[key];
    if (typeof value === 'string') text.style[key] = value;
  }
  svg.appendChild(text);
  document.body.appendChild(svg);
  const { width, height } = text.getBBox();
  document.body.removeChild(svg);
  return { width, height };
};

type Coord = [x: number, y: number];

/* svg坐标转屏幕坐标 */
export const getScreenCoord = ([x, y]: Coord, scale: number = 1, [offsetX, offsetY]: Coord = [0, 0]): Coord => {
  const screenX = (x - offsetX) * scale;
  const screenY = (y - offsetY) * scale;
  return [screenX, screenY];
};

/* 屏幕坐标转svg坐标 */
export const getSvgCoord = ([x, y]: Coord, scale: number = 1, [offsetX, offsetY]: Coord = [0, 0]): Coord => {
  const svgX = x / scale + offsetX;
  const svgY = y / scale + offsetY;
  return [svgX, svgY];
};

/* svg定点缩放后获取新的偏移量 */
export const getSvgFixedPointOffset = ([x, y]: Coord, [offsetX, offsetY]: Coord, scale: number, newScale: number) => {
  // 根据当前位置计算新的偏移量 公式: a * scale - offset * scale = a * scale2 - offset2 * scale2
  const newOffsetX = (x * newScale - (x - offsetX) * scale) / newScale;
  const newOffsetY = (y * newScale - (y - offsetY) * scale) / newScale;
  return [newOffsetX, newOffsetY];
};
