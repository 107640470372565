import Workflow from "@/components/Workflow";
import styles from './Home.module.less';

const Home = () => {
  return (
    <div className={styles.container}>
      <Workflow />
    </div>
  );
};

export default Home;
