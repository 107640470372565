type LineProps = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  stroke: string;
  strokeWidth: number;
};

const Line = (props: LineProps) => {
  const { x1, y1, x2, y2, stroke, strokeWidth } = props;
  return (
    <line
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      style={{
        stroke: stroke,
        strokeWidth: strokeWidth,
      }}
    />
  );
};

type Props = {
  width: number;
  height: number;
  lineOptions: {
    gap: number;
    stroke: string;
    strokeWidth: number;
  };
  scale: number;
  offset: {
    left: number;
    top: number;
  }
};

const WorkflowBg = (props: Props) => {
  const {
    width,
    height,
    lineOptions,
    scale,
    offset,
  } = props;
  
  // 背景线
  const gap = lineOptions.gap * scale;
  const offsetLeft = offset.left * scale % gap;
  const offsetTop = offset.top * scale % gap;

  const horizontalCount = Math.ceil(height / gap) + 1;
  const horizontalLines = new Array(horizontalCount).fill(null).map((_, index) => ({
    key: `horizontal-${index}`,
    stroke: lineOptions.stroke,
    strokeWidth: lineOptions.strokeWidth,
    x1: -gap-offsetLeft,
    y1: gap * index - offsetTop,
    x2: width - offsetLeft + gap,
    y2: gap * index - offsetTop,
  }));

  const verticalCount = Math.ceil(width / gap) + 1;
  const verticalLinges = new Array(verticalCount).fill(null).map((_, index) => ({
    key: `vertical-${index}`,
    stroke: lineOptions.stroke,
    strokeWidth: lineOptions.strokeWidth,
    x1: gap * index - offsetLeft,
    y1: -gap-offsetTop,
    x2: gap * index - offsetLeft,
    y2: height - offsetTop + gap,
  }));

  return (
    <g xlinkTitle="背景板">
      {horizontalLines.map(item => (
        <Line {...item} />
      ))}
      {verticalLinges.map(item => (
        <Line {...item} />
      ))}
    </g>
  );
};

export default WorkflowBg;
