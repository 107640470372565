import { Button, Typography } from "antd";

const { Paragraph, Text } = Typography;

type Props = {
  offset: Workflow.Offset;
  scale: number;
  onOffsetChange?: (offset: Workflow.Offset) => void;
};

const WorkflowBeacon = (props: Props) => {
  const { offset, scale, onOffsetChange } = props;

  const handleOffsetReset = () => {
    if (onOffsetChange) onOffsetChange({ left: 0, top: 0 });
  };

  return (
    <Typography style={{ position: 'absolute', top: 4, left: 4 }}>
      <Paragraph>
        <Text strong>偏移: </Text>
        <Text code>{offset.left.toFixed(2)},{offset.top.toFixed(2)}</Text>
        <Button size="small" type="ghost" onClick={handleOffsetReset}>复位</Button>
      </Paragraph>
      <Paragraph>
        <Text strong>缩放: </Text>
        <Text code>{scale.toFixed(1)}</Text>
      </Paragraph>
    </Typography>
  );
};

export default WorkflowBeacon;
