import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getScreenCoord, getSvgCoord } from '@/utils/svg.util';
import styles from './WorkflowTask.module.less';

type Props = {
  task: Workflow.Task;
  offset: {
    left: number;
    top: number;
  };
  scale: number;
  selected?: boolean;
  onClick?: (e: React.MouseEvent<SVGGElement, MouseEvent>, task: Workflow.Task) => void;
  onSelectedChange?: (selected: boolean, key: number, multi: boolean) => void;
  showTitleEditor?: (task: Workflow.Task) => void;
  onChange?: (key: number, options: Workflow.TaskUpdateOptions) => void;
  onConnectStart?: (coord: Workflow.Coordinate) => void;
  onConnecting?: (coord: Workflow.Coordinate) => void;
  onConnectEnd?: (position: string, startId: number, endId: number) => void;
  onConnectClear?: () => void;
};

// [position, startId, endId]
const connector: [string, number, number] = ['', 0, 0];

const WorkflowTask = (props: Props) => {
  const {
    task,
    offset,
    scale,
    selected,
    onSelectedChange,
    showTitleEditor,
    onChange,
    onConnectStart,
    onConnecting,
    onConnectEnd,
    onConnectClear,
  } = props;

  const [actived, setActived] = useState<boolean | undefined>(selected);
  const [activedEndpointKey, setActivedEndpointKey] = useState<string | null>(null);

  useEffect(() => {
    if (selected !== undefined) setActived(selected);
  }, [selected]);

  const handleClick = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    e.stopPropagation();
    const nextActived = e.ctrlKey ? !actived : true;
    if (onSelectedChange) onSelectedChange(nextActived, task.id, e.ctrlKey);
    if (selected === undefined) setActived(nextActived);
  };

  const handleDoubleClick = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    e.stopPropagation();
    if (showTitleEditor) showTitleEditor(task);
  };

  const handleMouseDown = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    if (e.ctrlKey) return;
    const target = e.currentTarget;
    const initClientX = e.clientX, initClientY = e.clientY;
    const { origin } = task.canvasOptions;
    const [initX, initY] = getScreenCoord(origin, scale, [offset.left, offset.top]);

    const handleMouseMove = (e: MouseEvent) => {
      target.style.pointerEvents = 'none';
      const clientX = initX + (e.clientX - initClientX), clientY = initY + (e.clientY - initClientY);
      const origin = getSvgCoord([clientX, clientY], scale, [offset.left, offset.top]);
      if (onChange) onChange(task.id, { origin });
    };

    document.addEventListener('mousemove', handleMouseMove);

    document.addEventListener('mouseup', () => {
      target.style.pointerEvents = '';
      document.removeEventListener('mousemove', handleMouseMove);
    }, { once: true });
  };
  
  const handleMouseUp = () => {
    try {
      if (!connector[0] || !connector[1] || connector[1] === task.id) return;
      connector[2] = task.id;
      if (onConnectEnd) onConnectEnd(...connector);
    } finally {
      connector[0] = '';
      connector[1] = connector[2] = 0;
    }
  };

  const handleEndpointMouseDown = (e: React.MouseEvent<SVGCircleElement, MouseEvent>, endpoint: Workflow.Endpoint) => {
    e.stopPropagation();
    setActivedEndpointKey(endpoint.position);
    connector[0] = endpoint.position;
    connector[1] = task.id;
    const initClientX = e.clientX, initClientY = e.clientY;
    if (onConnectStart) onConnectStart([endpoint.x, endpoint.y]);
    const handleMouseMove = (e: MouseEvent) => {
      const clientX = e.clientX, clientY = e.clientY;
      if (onConnecting) onConnecting([endpoint.x + clientX - initClientX, endpoint.y + clientY - initClientY]);
    };
    document.addEventListener('mousemove', handleMouseMove);
    const handleEndpointMouseUp = () => {
      setActivedEndpointKey(null);
      if (onConnectClear) onConnectClear();
      document.removeEventListener('mousemove', handleMouseMove);
    };
    document.addEventListener('mouseup', handleEndpointMouseUp, { once: true });
  };

  const canvasOptions = task.canvasOptions;

  // 字体大小
  const fontSize = canvasOptions.fontSize * scale;

  const { size, padding, origin, arcR } = canvasOptions;

  // 长宽
  const width = (size.width + padding.x * 2) * scale, height = (size.height + padding.y * 2) * scale;

  // 中心店坐标
  const [x, y] = getScreenCoord(origin, scale, [offset.left, offset.top]);

  // 盒子左右圆弧半径
  const boxArcR = arcR.x * scale;

  // 盒子坐标
  const [boxX, boxY] = getScreenCoord([origin[0] - padding.x, origin[1] - size.height / 2 - padding.y], scale, [offset.left, offset.top]);

  // 盒子路径
  // const boxPath = `M${boxX} ${boxY} h${width} v${height} h-${width} Z`;
  const boxPath = `M${boxX} ${boxY} h${width} a${boxArcR} ${(size.height / 2 + padding.y) * scale} 0 0 1 0 ${height} h-${width} a-${boxArcR} ${(size.height / 2 + padding.y) * scale} 0 0 1 0 -${height} Z`;

  // 端点
  const endpoints = canvasOptions.endpoints.map(endpoint => {
    const [x, y] = getScreenCoord([endpoint.x, endpoint.y], scale, [offset.left, offset.top])
    return {
      ...endpoint,
      x,
      y,
      r: endpoint.r * scale,
    }
  });

  return (
    <g
      className={classNames(styles.task, { [styles.active]: actived })}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <path className={styles.box} d={boxPath} />
      <text className={styles.text} dominantBaseline="central" x={x} y={y} style={{ fontSize: fontSize }}>{task.title}</text>
      {endpoints.map(endpoint => (
        <circle
          key={endpoint.position}
          className={classNames(styles.endpoint, { [styles.active]: activedEndpointKey === endpoint.position })}
          cx={endpoint.x}
          cy={endpoint.y}
          r={endpoint.r}
          onMouseDown={(e) => handleEndpointMouseDown(e, endpoint)}
        />
      ))}
    </g>
  );
};

export default WorkflowTask;
